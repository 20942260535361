import React from 'react';

import SocialContact from '../../../components/SocialContact/SocialContact';

import FacebookIcon from '../../../assets/home/facebook.png';
import WhatsappIcon from '../../../assets/home/whatsapp-footer.png';
import InstagramIcon from '../../../assets/home/instagram.png';
import GCLIcon from '../../../assets/home/ventinho.png';
import BackFooter from '../../../assets/home/back_footer.png';

import SocialIconPhone from '../../../assets/home/phone-call.png';
import SocialIconMail from '../../../assets/home/mail.png';
import SocialIconStore from '../../../assets/home/location-pointer.png';

import './Social.scss';

const socialContactTextOne = (
  <>
    Rua Prof. Francisco das
    <br />
    Chagas Almeida, 31
    <br />
    João Pessoa - PB
    <br />
    CEP: 58051-500
  </>
);

const socialContactTextTwo = (
  <>
    (81) 9 9171-2678
    <br />
    (83) 9 9675-9057
    <br />
    (83) 9 9603-3139
    <br />
    (83) 9 9957-1264
  </>
);

const socialContactTextThree = (
  <>
    gclarerefrigeracao@gmail.com
  </>
);

export default function Social() {
  return (
    <div className="Social">
      <div className="BackImage">
        <img src={BackFooter} alt="GCL" />
      </div>
      <div className="SocialsFollow">
        <div className="SocialLogo">
          <img src={GCLIcon} alt="GCL" />
        </div>
        <div className="SocialCall">
          <span>SIGA NAS REDES SOCIAIS</span>
        </div>
        <div className="SocialsIcons">
          <a href="https://www.facebook.com/GCL-Ar-Condicionado-e-Refrigera%C3%A7%C3%A3o-101248998847750" target="_blank" rel="noreferrer"><img src={FacebookIcon} alt="Facebook" /></a>
          <a href="https://wa.me/5583999455148" target="_blank" rel="noreferrer"><img src={WhatsappIcon} alt="Whatsapp" /></a>
          <a href="https://www.instagram.com/gclarcondicionado" target="_blank" rel="noreferrer"><img src={InstagramIcon} alt="Instagram" /></a>
        </div>
      </div>
      <div className="ContactOne">
        <SocialContact
          title="Atendimento presencial"
          text={socialContactTextOne}
          imgURL={SocialIconStore}
        />
      </div>
      <div className="ContactTwo">
        <SocialContact
          title="Atendimento telefônico"
          text={socialContactTextTwo}
          imgURL={SocialIconPhone}
        />
      </div>
      <div className="ContactThree">
        <SocialContact
          title="Atendimento por email"
          text={socialContactTextThree}
          imgURL={SocialIconMail}
        />
      </div>
    </div>
  );
}
