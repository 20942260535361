import React from 'react';
import { Link } from 'react-scroll';

export default function ScrollLink({ to, y, children }) {
  return (
    <Link
      activeClass="active"
      to={to}
      spy
      offset={y}
      duration={300}
    >
      {children}
    </Link>
  );
}
