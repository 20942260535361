import React from 'react';

import './style.scss';

export default function Service({ imgURL, title, text }) {
  return (
    <div className="Service">
      <img src={imgURL} alt="service" className="ImgStyle" />
      <span className="ServiceTitle">{ title }</span>
      <span className="ServiceText">{ text }</span>
    </div>
  );
}
