import React from 'react';
import SectionTitle from '../../../components/SectionTitle/SectionTitle';
import Service from '../../../components/Service/Service';

import serviceOneIcon from '../../../assets/home/vassourinha.png';
import serviceTwoIcon from '../../../assets/home/arzinho.png';
import serviceThreeIcon from '../../../assets/home/ferramentas.png';

import './Services.scss';

export default function Services() {
  return (
    <div className="Services">

      <div className="Title">
        <SectionTitle>SERVIÇOS</SectionTitle>
      </div>
      <div className="ServiceOne">
        <Service
          title="Higienização"
          text="Higienização, lavagem corretiva ou preventiva."
          imgURL={serviceOneIcon}
        />
      </div>
      <div className="vSeparatorLeft" />
      <div className="ServiceTwo">
        <Service
          title="Reparo de Ar Condicionado"
          text="Reparos, soldas, carga de gás."
          imgURL={serviceTwoIcon}
        />
      </div>
      <div className="vSeparatorRight" />
      <div className="ServiceThree">
        <Service
          title="Instalação e manutenção"
          text="Instalação e Manutenção com total segurança."
          imgURL={serviceThreeIcon}
        />
      </div>
    </div>
  );
}
