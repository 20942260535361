import React from 'react';

import './style.scss';
import bolas from '../../assets/home/bolas.png';

export default function Bolas() {
  return (
    <div className="ImgWrapper">
      <img className="ImgStyled" src={bolas} alt="Home" />
    </div>

  );
}
