import React from 'react';

import './style.scss';

export default function Layout({ children }) {
  return (
    <div className="LayoutStyled">
      { children }
    </div>
  );
}
