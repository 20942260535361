import React from 'react';

import ScrollLink from '../../../components/ScrollLink/ScrollLink';

import LogoBarra from '../../../assets/home/logo_barra.png';
import WhatsContact from '../../../assets/home/whatsapp.png';

import './Menu.scss';

export default function Menu() {
  return (
    <div className="Menu">
      <img src={LogoBarra} className="LogoTopo" alt="HOME" />
      <div className="MenuItems">
        <ScrollLink
          to="Services"
          y={-60}
        >
          <span>Serviços</span>
        </ScrollLink>
        <ScrollLink
          to="Contact"
          y={-60}
        >
          <span>Orçamento</span>
        </ScrollLink>
        <ScrollLink
          to="AboutUs"
          y={-60}
        >
          <span>Sobre Nós</span>
        </ScrollLink>
        <ScrollLink
          to="Social"
          y={-60}
        >
          <span>Contato</span>
        </ScrollLink>
      </div>
      <div className="WhatsContact">
        <a href="https://wa.me/5583999455148" target="_blank" rel="noreferrer"><img src={WhatsContact} alt="HOME" /></a>
      </div>

    </div>
  );
}
