import React from 'react';
import About from '../../../components/About/About';

import AboutOneIcon from '../../../assets/home/31632.png';
import AboutTwoIcon from '../../../assets/home/107828.png';
import AboutThreeIcon from '../../../assets/home/2083314.png';

import './AboutUs.scss';

const AboutOneText = (
  <>
    Equipe capacitada para
    <br />
    realização do serviço e com
    <br />
    diferencial no atendimento.
  </>
);

const AboutTwoText = (
  <>
    Alta qualidade nos
    <br />
    serviços prestados.
    <br />
    O melhor custo-benefício.
  </>
);

const AboutThreeText = (
  <>
    Pontualidade, trazendo conforto e
    <br />
    confiança do começo ao
    <br />
    fim do serviço
  </>
);

export default function AboutUs() {
  return (
    <div className="AboutUs">
      <div className="TitleOne">
        Porque escolher a
        {' '}
        <b>GCL</b>
        ?
      </div>
      <div className="TitleTwo">
        Ao procurar por serviços de alta qualidade, a GCL é a melhor opção.
      </div>
      <div className="AboutOne">
        <About
          title={(
            <>
              Profissionais
              {' '}
              <br />
              Qualificados
            </>
)}
          flex="Left"
          text={AboutOneText}
          textAlign="Left"
          imgURL={AboutOneIcon}
        />
      </div>
      <div className="AboutTwo">
        <About
          title={(
            <>
              Qualidade
              no
              {' '}
              <br />
              Serviço
            </>
)}
          text={AboutTwoText}
          flex="Center"
          textAlign="Center"
          imgURL={AboutTwoIcon}
        />
      </div>
      <div className="AboutThree">
        <About
          title={(
            <>
              Pontualidade
              {' '}
              <br />
              no Atendimento
            </>
)}
          text={AboutThreeText}
          flex="Right"
          textAlign="Right"
          imgURL={AboutThreeIcon}
        />
      </div>
    </div>
  );
}
