import React from 'react';

import './style.scss';

export default function SectionTitle({ children, color = 'white' }) {
  return (
    <div className="SectionTitle">
      <span color={color}>{ children }</span>
    </div>

  );
}
