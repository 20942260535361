import React from 'react';
import Bolas from '../../../components/Bolas/Bolas';

import './Cover.scss';

export default function Cover() {
  return (
    <div className="Cover">
      <div className="Slogan">
        <span>
          Pensou em
          {' '}
          <b>ar condicionado</b>
          ?
        </span>
        <span>
          Pensou em
          {' '}
          <b>GCL</b>
          .
        </span>
      </div>
      <Bolas />
    </div>
  );
}
