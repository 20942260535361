import React from 'react';
import axios from 'axios';
import toastify from '../../../utils/toastify';

import './Contact.scss';

import PhoneIcon from '../../../assets/home/phone-call-inverted.png';
import WhatsappButton from '../../../assets/home/whatsapp.png';

export default function Contact() {
  const [nome, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [whatsapp, setWhatsapp] = React.useState('');
  const [assunto, setAssunto] = React.useState('');

  async function sendMail() {
    if (!nome) {
      toastify('warn', 'Por favor, informe seu nome.');
    } else if (!email && !whatsapp) {
      toastify('warn', 'Por favor, informe seu e-mail ou um número Whatsapp.');
    } else if ((!email.includes('@') || email.length < 5) && !whatsapp) {
      toastify('warn', 'Por favor, informe um email válido.');
    } else if (!assunto) {
      toastify('warn', 'Por favor, digite uma mensagem.');
    } else {
      const dataToSend = {
        nome, email, whatsapp, assunto,
      };
      const sendedEmail = await axios.post('mail/gcl', dataToSend, {
        baseURL: 'https://api.gclarcondicionado.com.br/',
        headers: {
          'Content-type': 'application/json',
          Authorization: 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJTZXJ2aWNlIE5vdyIsImlhdCI6MTYzMDA5NDE4NCwiZXhwIjo0MDkxNTQzNzg2LCJhdWQiOiJ3d3cudGltLWFkY2MuY29tIiwic3ViIjoiIn0.mJht62N8NqI6ybw6eOr2GlkmwCv2yhgTFtjLxvNlEQ4',
        },
      });
      console.log(sendedEmail);
    }
  }

  function handleChange(e) {
    if (e.target.name === 'email') {
      setEmail(e.target.value);
    }
    if (e.target.name === 'nome') {
      setName(e.target.value);
    }
    if (e.target.name === 'whatsapp') {
      setWhatsapp(e.target.value);
    }
    if (e.target.name === 'assunto') {
      setAssunto(e.target.value);
    }
  }

  return (
    <div className="Contact">
      <div className="PhoneIcon FlexEnd">
        <img src={PhoneIcon} alt="Phone" />
      </div>
      <div className="PhoneTitle FlexEnd">
        <span>Atendimento telefônico:</span>
      </div>
      <div className="PhoneLineOne FlexEnd">
        <span>(81) 9 9171-2678 | (83) 9 9675-9057</span>
      </div>
      <div className="PhoneLineTwo FlexEnd">
        <span>(83) 9 9603-3139 | (83) 9 9957-1264</span>
      </div>
      <div className="WhatsappButton FlexStart">
        <a href="https://wa.me/5583999455148" target="_blank" rel="noreferrer"><img src={WhatsappButton} alt="Phone" /></a>
      </div>
      <div className="Order FlexStart">
        <span>Solicite seu orçamento!</span>
      </div>
      <div className="Email FormField FlexEnd">
        <input type="text" placeholder="e-mail" name="email" value={email} onChange={handleChange} />
      </div>
      <div className="FormField Nome FlexEnd">
        <input type="text" placeholder="nome" value={nome} name="nome" onChange={handleChange} />
      </div>
      <div className="FormField Whatsapp FlexEnd">
        <input type="text" placeholder="Seu número de Whatsapp" value={whatsapp} name="whatsapp" onChange={handleChange} />
      </div>
      <div className="FormField Assunto FlexEnd">
        <textarea onChange={handleChange} name="assunto" placeholder="Digite sua mensagem" value={assunto} />
      </div>
      <div className="FormField Button FlexEnd">
        <input type="button" value="enviar" onClick={() => sendMail()} />
      </div>
    </div>
  );
}
