import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Layout from './components/Layout/Layout';
import Home from './screens/HomeScreen/Home';

function App() {
  return (
    <Router>
      <Layout>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/services"><h1>Services</h1></Route>
        </Switch>
      </Layout>

      <ToastContainer />
    </Router>
  );
}

export default App;
