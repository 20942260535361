import React from 'react';

import './style.scss';

export default function SocialContact({
  imgURL, title, text, textAlign = 'Center', flex = 'Center',
}) {
  return (
    <div className={`SocialContact ${flex}Flex`}>
      <img src={imgURL} alt="about" className="ImgStyle" />
      <span className={`SocialTitle  ${textAlign}Text`}>{ title }</span>
      <span className={`SocialText  ${textAlign}Text`}>{ text }</span>
    </div>
  );
}
