import React from 'react';

import {
  AboutUs, Contact, Cover, Menu, Services, Social,
} from './sections';

import './Home.scss';

export default function HomeScreen() {
  return (
    <div className="Home">
      <Menu />
      <Cover />
      <Services />
      <AboutUs />
      <Contact />
      <Social />
    </div>
  );
}
